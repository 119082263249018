@use '/src/app/core/styles/fonts';
@use '/src/app/core/styles/boxes';
@use '/src/app/core/styles/colors';
@use '/src/app/core/styles/transitions';

.sq-button {
  font-family: fonts.$font-family;
  outline: none;
  position: relative;
  padding: 0 boxes.$gap-x2;
  font-weight: fonts.$font-semibold;
  font-size: 16px;
  text-align: center;
  border-radius: boxes.$radius-standard;
  border: none;
  cursor: pointer;
  width: max-content;
  min-height: 64px;
  transition:
    background-color transitions.$time-standard transitions.$easing,
    color transitions.$time-standard transitions.$easing,
    transform transitions.$time-standard * 4 transitions.$easeOut,
    border-radius transitions.$time-standard * 4 transitions.$easeOut,
    box-shadow transitions.$time-standard * 4 transitions.$easeOut;
  color: colors.$sq-white;
  white-space: nowrap;

  // This is to vertically center the text on anchor tags
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.sq-button-small {
    min-height: 32px;
    font-size: 14px;
    padding: 0 boxes.$gap;
  }

  &.sq-button-regular {
    box-shadow: boxes.$box-shadow;
    min-width: 144px;
    &:before {
      /*
          This is a good way of solving edge glitch :D
          comment this :before & the :before in the :hover, then approach a button slowly from the bottom up to see what I mean
        */
      content: '';
      display: block;
      width: 100%;
      height: 0;
      position: absolute;
      top: 100%;
      left: 0;
    }
    &:hover {
      box-shadow: boxes.$box-shadow-darker;
      transform: translateY(-(boxes.$gap-quarter));
      &:before {
        height: boxes.$gap-quarter;
      }
    }
    &:active {
      transform: translateY(boxes.$gap-quarter * -0.6);
      box-shadow: boxes.$box-shadow-darker-active;
      border-radius: boxes.$radius-standard * 2;
    }
  }

  @mixin button-variant($color, $color-light) {
    background-color: $color;
    &:hover,
    &:active {
      background-color: $color-light;
    }

    &.sq-button-outline {
      border: 1px solid $color;
      color: $color;
      background-color: transparent;
      &:hover {
        background-color: $color-light;
        border: 1px solid $color-light;
        color: colors.$sq-white;
      }
    }
    &.sq-button-inverted {
      background-color: colors.$sq-white;
      color: $color;
      &.sq-button-outline {
        border: 1px solid colors.$sq-white;
        color: colors.$sq-white;
        background-color: transparent;
        &:hover {
          background-color: colors.$sq-white;
          border: 1px solid colors.$sq-white;
          color: $color;
        }
      }
    }
  }

  &.sq-button-primary:not(:disabled) {
    @include button-variant(colors.$sq-orange, colors.$sq-orange-light);
  }

  &.sq-button-secondary:not(:disabled) {
    @include button-variant(colors.$sq-green, colors.$sq-green-light);
  }

  &.sq-button-warn:not(:disabled) {
    @include button-variant(colors.$sq-watermelon, colors.$sq-watermelon-light);
  }

  &.sq-button-disabled,
  &:disabled {
    background-color: colors.$sq-grey;
    color: colors.$sq-grey-dark;
    border: 1px solid colors.$sq-grey-dark;
    cursor: default;
    &:hover {
      // disable hover effect on disabled buttons
      transform: none;
      box-shadow: boxes.$box-shadow;
    }
  }
  .mat-mdc-progress-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -16px 0 0 -16px;
  }
}
