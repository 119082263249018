$box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.02);
$box-shadow-vertical: 8px 0px rgba(0, 0, 0, 0.02);
$box-shadow-slightly-darker: 0px 8px 0px rgba(0, 0, 0, 0.04);
$box-shadow-darker: 0px 8px 0px rgba(0, 0, 0, 0.08);
$box-shadow-darker-active: 0px 6px 0px rgba(0, 0, 0, 0.08);
$box-shadow-modal: 0px 8px 0px rgba(0, 0, 0, 0.35);

$gap: 16px;
$gap-half: $gap / 2;
$gap-quarter: $gap / 4;
$gap-x1-5: $gap * 1.5;
$gap-x2: $gap * 2;
$gap-x3: $gap * 3;
$gap-x4: $gap * 4;
$gap-x5: $gap * 5;
$gap-x6: $gap * 6;

$radius-standard: 4px;
$radius-small: $radius-standard / 2;
