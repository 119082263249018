$sq-black-rich: #141414;
$sq-black: #414042;
$sq-grey-extra-dark: #67646a;
$sq-grey-dark: #a3a3a7;
$sq-grey-mid: #c9c9cd;
$sq-grey: #e5e5e7;
$sq-grey-light: #f1f1f2;
$sq-grey-body: #fafafa;
$sq-white: #ffffff;
$sq-orange-dark: #e35816;
$sq-orange: #f16421;
$sq-orange-light: #fb6f2d;
$sq-cyan-dark: #599faf;
$sq-cyan: #92c4d0;
$sq-cyan-20: #cee5e8;
$sq-cyan-light: #c1dee4;
$sq-cyan-extra-light: #ddedee;
$sq-green-dark: #229b0e;
$sq-green: #18ac00;
$sq-green-light: #21c805;
$sq-watermelon-dark: #e92e4f;
$sq-watermelon: #ff4667;
$sq-watermelon-light: #ff6c86;
$sq-purple-dark: #da1f79;
$sq-purple: #ee4d9b;
$sq-purple-light: #ff60ad;
$sq-gold-dark: #fcb307;
$sq-gold: #ffc700;
$sq-gold-light: #ffd84d;
$sq-blue: #61afff;
$sq-blue-light: #81cbff;
$sq-blue-dark: #007bcc;
$sq-red: #fb2d2d;

$color-font: $sq-black;
$color-font-light: $sq-grey-dark;
$color-font-extra-light: $sq-grey-mid;
