@use '@angular/material' as mat;
@use '/src/app/core/styles/colors';
@use '/src/app/core/styles/boxes';
@use '/src/app/core/styles/fonts';

@include mat.core();

$theme: mat.define-theme(
  (
    color: (
      use-system-variables: true,
    ),
    typography: (
      plain-family: fonts.$font-family,
      brand-family: fonts.$font-family,
      bold-weight: 700,
      medium-weight: 600,
      regular-weight: 400,
      use-system-variables: true,
    ),
    density: (
      scale: -1,
    ),
  )
);

:root,
:host {
  ///////////////////////////////////////////////////////////////
  /// Colors - Matched to the design guidelines
  ///////////////////////////////////////////////////////////////

  --mat-sys-background: #{colors.$sq-white};
  --mat-sys-on-background: #{colors.$sq-black};
  --mat-sys-surface: #{colors.$sq-white};
  --mat-sys-surface-dim: #{colors.$sq-white};
  --mat-sys-surface-bright: #{colors.$sq-white};
  --mat-sys-surface-container-lowest: #{colors.$sq-white};
  --mat-sys-surface-container-low: #{colors.$sq-white};
  --mat-sys-surface-container: #{colors.$sq-white};
  --mat-sys-surface-container-high: #{colors.$sq-white};
  --mat-sys-surface-container-highest: #{colors.$sq-white};
  --mat-sys-on-surface: #{colors.$sq-black};
  --mat-sys-surface-variant: #{colors.$sq-white};
  --mat-sys-on-surface-variant: #{colors.$sq-black};
  --mat-sys-inverse-surface: #{colors.$sq-black};
  --mat-sys-inverse-on-surface: #{colors.$sq-white};
  --mat-sys-outline: #{colors.$sq-grey-mid};
  --mat-sys-outline-variant: #{colors.$sq-grey};
  --mat-sys-shadow: #{colors.$sq-black-rich};
  --mat-sys-scrim: #{colors.$sq-black-rich};
  --mat-sys-surface-tint: #{colors.$sq-orange-dark};
  --mat-sys-primary: #{colors.$sq-orange};
  --mat-sys-on-primary: #{colors.$sq-white};
  --mat-sys-primary-container: #{colors.$sq-orange};
  --mat-sys-on-primary-container: #{colors.$sq-white};
  --mat-sys-inverse-primary: #{colors.$sq-white};
  --mat-sys-secondary: #{colors.$sq-cyan};
  --mat-sys-on-secondary: #{colors.$sq-white};
  --mat-sys-secondary-container: #{colors.$sq-cyan};
  --mat-sys-on-secondary-container: #{colors.$sq-white};
  --mat-sys-tertiary: #{colors.$sq-green};
  --mat-sys-on-tertiary: #{colors.$sq-white};
  --mat-sys-tertiary-container: #{colors.$sq-green};
  --mat-sys-on-tertiary-container: #{colors.$sq-white};
  --mat-sys-error: #{colors.$sq-watermelon};
  --mat-sys-on-error: #{colors.$sq-watermelon};
  --mat-sys-error-container: #{colors.$sq-watermelon};
  --mat-sys-on-error-container: #{colors.$sq-watermelon};
  --mat-sys-neutral: #{colors.$sq-grey-extra-dark};
  --mat-sys-neutral-variant: #{colors.$sq-grey-extra-dark};
  --mat-sys-primary-fixed: #{colors.$sq-orange};
  --mat-sys-primary-fixed-dim: #{colors.$sq-orange-dark};
  --mat-sys-on-primary-fixed: #{colors.$sq-black};
  --mat-sys-on-primary-fixed-variant: #{colors.$sq-black};
  --mat-sys-secondary-fixed: #{colors.$sq-cyan};
  --mat-sys-secondary-fixed-dim: #{colors.$sq-cyan-dark};
  --mat-sys-on-secondary-fixed: #{colors.$sq-black};
  --mat-sys-on-secondary-fixed-variant: #{colors.$sq-black};
  --mat-sys-tertiary-fixed: #{colors.$sq-green};
  --mat-sys-tertiary-fixed-dim: #{colors.$sq-green-dark};
  --mat-sys-on-tertiary-fixed: #{colors.$sq-white};
  --mat-sys-on-tertiary-fixed-variant: #{colors.$sq-white};
  --mat-sys-inverse-secondary: #{colors.$sq-white};
  --mat-sys-inverse-tertiary: #{colors.$sq-white};
  --mat-sys-inverse-error: #ffb2b8;
  --mat-sys-error-fixed: #ffdadb;
  --mat-sys-error-fixed-dim: #ffb2b8;
  --mat-sys-on-error-fixed: #3b0811;
  --mat-sys-on-error-fixed-variant: #72333a;

  ///////////////////////////////////////////////////////////////
  /// Typography - Matched to the design guidelines
  ///////////////////////////////////////////////////////////////

  // .mat-display-large .mat-display-medium .mat-display-small all represent Page Title in the design guidelines
  --mat-sys-display-large-font: #{fonts.$font-family};
  --mat-sys-display-large-line-height: #{fonts.$page-title-line-height};
  --mat-sys-display-large-size: #{fonts.$page-title-fontsize};
  --mat-sys-display-large-tracking: #{fonts.$page-title-tracking};
  --mat-sys-display-large-weight: #{fonts.$page-title-weight};
  --mat-sys-display-large: var(--mat-sys-display-large-weight) var(--mat-sys-display-large-size) /
    var(--mat-sys-display-large-line-height) var(--mat-sys-display-large-font);

  --mat-sys-display-medium-font: #{fonts.$font-family};
  --mat-sys-display-medium-line-height: #{fonts.$page-title-line-height};
  --mat-sys-display-medium-size: #{fonts.$page-title-fontsize};
  --mat-sys-display-medium-tracking: #{fonts.$page-title-tracking};
  --mat-sys-display-medium-weight: #{fonts.$page-title-weight};
  --mat-sys-display-medium: var(--mat-sys-display-medium-weight) var(--mat-sys-display-medium-size) /
    var(--mat-sys-display-medium-line-height) var(--mat-sys-display-medium-font);

  --mat-sys-display-small-font: #{fonts.$font-family};
  --mat-sys-display-small-line-height: #{fonts.$page-title-line-height};
  --mat-sys-display-small-size: #{fonts.$page-title-fontsize};
  --mat-sys-display-small-tracking: #{fonts.$page-title-tracking};
  --mat-sys-display-small-weight: #{fonts.$page-title-weight};
  --mat-sys-display-small: var(--mat-sys-display-small-weight) var(--mat-sys-display-small-size) /
    var(--mat-sys-display-small-line-height) var(--mat-sys-display-small-font);

  // .mat-headline-large .mat-headline-medium .mat-headline-small all represent Section Title in the design guidelines
  --mat-sys-headline-large-font: #{fonts.$font-family};
  --mat-sys-headline-large-line-height: #{fonts.$section-title-line-height};
  --mat-sys-headline-large-size: #{fonts.$section-title-fontsize};
  --mat-sys-headline-large-tracking: #{fonts.$section-title-tracking};
  --mat-sys-headline-large-weight: #{fonts.$section-title-weight};
  --mat-sys-headline-large: var(--mat-sys-headline-large-weight) var(--mat-sys-headline-large-size) /
    var(--mat-sys-headline-large-line-height) var(--mat-sys-headline-large-font);

  --mat-sys-headline-medium-font: #{fonts.$font-family};
  --mat-sys-headline-medium-line-height: #{fonts.$section-title-line-height};
  --mat-sys-headline-medium-size: #{fonts.$section-title-fontsize};
  --mat-sys-headline-medium-tracking: #{fonts.$section-title-tracking};
  --mat-sys-headline-medium-weight: #{fonts.$section-title-weight};
  --mat-sys-headline-medium: var(--mat-sys-headline-medium-weight) var(--mat-sys-headline-medium-size) /
    var(--mat-sys-headline-medium-line-height) var(--mat-sys-headline-medium-font);

  --mat-sys-headline-small-font: #{fonts.$font-family};
  --mat-sys-headline-small-line-height: #{fonts.$section-title-line-height};
  --mat-sys-headline-small-size: #{fonts.$section-title-fontsize};
  --mat-sys-headline-small-tracking: #{fonts.$section-title-tracking};
  --mat-sys-headline-small-weight: #{fonts.$section-title-weight};
  --mat-sys-headline-small: var(--mat-sys-headline-small-weight) var(--mat-sys-headline-small-size) /
    var(--mat-sys-headline-small-line-height) var(--mat-sys-headline-small-font);

  // .mat-title-large .mat-title-medium .mat-title-small all represent Sub Title in the design guidelines
  --mat-sys-title-large-font: #{fonts.$font-family};
  --mat-sys-title-large-line-height: #{fonts.$sub-title-line-height};
  --mat-sys-title-large-size: #{fonts.$sub-title-fontsize};
  --mat-sys-title-large-tracking: #{fonts.$sub-title-tracking};
  --mat-sys-title-large-weight: #{fonts.$sub-title-weight};
  --mat-sys-title-large: var(--mat-sys-title-large-weight) var(--mat-sys-title-large-size) /
    var(--mat-sys-title-large-line-height) var(--mat-sys-title-large-font);

  --mat-sys-title-medium-font: #{fonts.$font-family};
  --mat-sys-title-medium-line-height: #{fonts.$sub-title-line-height};
  --mat-sys-title-medium-size: #{fonts.$sub-title-fontsize};
  --mat-sys-title-medium-tracking: #{fonts.$sub-title-tracking};
  --mat-sys-title-medium-weight: #{fonts.$sub-title-weight};
  --mat-sys-title-medium: var(--mat-sys-title-medium-weight) var(--mat-sys-title-medium-size) /
    var(--mat-sys-title-medium-line-height) var(--mat-sys-title-medium-font);

  --mat-sys-title-small-font: #{fonts.$font-family};
  --mat-sys-title-small-line-height: #{fonts.$sub-title-line-height};
  --mat-sys-title-small-size: #{fonts.$sub-title-fontsize};
  --mat-sys-title-small-tracking: #{fonts.$sub-title-tracking};
  --mat-sys-title-small-weight: #{fonts.$sub-title-weight};
  --mat-sys-title-small: var(--mat-sys-title-small-weight) var(--mat-sys-title-small-size) /
    var(--mat-sys-title-small-line-height) var(--mat-sys-title-small-font);

  // .mat-label-large .mat-label-medium .mat-body-large .mat-body-medium all represent Copy in the design guidelines
  --mat-sys-label-large-font: #{fonts.$font-family};
  --mat-sys-label-large-line-height: #{fonts.$copy-line-height};
  --mat-sys-label-large-size: #{fonts.$copy-fontsize};
  --mat-sys-label-large-tracking: #{fonts.$copy-tracking};
  --mat-sys-label-large-weight: #{fonts.$copy-weight};
  --mat-sys-label-large-weight-prominent: #{fonts.$copy-weight};
  --mat-sys-label-large: var(--mat-sys-label-large-weight) var(--mat-sys-label-large-size) /
    var(--mat-sys-label-large-line-height) var(--mat-sys-label-large-font);

  --mat-sys-label-medium-font: #{fonts.$font-family};
  --mat-sys-label-medium-line-height: #{fonts.$copy-line-height};
  --mat-sys-label-medium-size: #{fonts.$copy-fontsize};
  --mat-sys-label-medium-tracking: #{fonts.$copy-tracking};
  --mat-sys-label-medium-weight: #{fonts.$copy-weight};
  --mat-sys-label-medium-weight-prominent: #{fonts.$font-bold};
  --mat-sys-label-medium: var(--mat-sys-label-medium-weight) var(--mat-sys-label-medium-size) /
    var(--mat-sys-label-medium-line-height) var(--mat-sys-label-medium-font);

  --mat-sys-body-large-font: #{fonts.$font-family};
  --mat-sys-body-large-line-height: #{fonts.$copy-line-height};
  --mat-sys-body-large-size: #{fonts.$copy-fontsize};
  --mat-sys-body-large-tracking: #{fonts.$copy-tracking};
  --mat-sys-body-large-weight: #{fonts.$copy-weight};
  --mat-sys-body-large: var(--mat-sys-body-large-weight) var(--mat-sys-body-large-size) /
    var(--mat-sys-body-large-line-height) var(--mat-sys-body-large-font);

  --mat-sys-body-medium-font: #{fonts.$font-family};
  --mat-sys-body-medium-line-height: #{fonts.$copy-line-height};
  --mat-sys-body-medium-size: #{fonts.$copy-fontsize};
  --mat-sys-body-medium-tracking: #{fonts.$copy-tracking};
  --mat-sys-body-medium-weight: #{fonts.$copy-weight};
  --mat-sys-body-medium: var(--mat-sys-body-medium-weight) var(--mat-sys-body-medium-size) /
    var(--mat-sys-body-medium-line-height) var(--mat-sys-body-medium-font);

  // .mat-label-small .mat-body-small all represent small in the design guidelines
  --mat-sys-label-small-font: #{fonts.$font-family};
  --mat-sys-label-small-line-height: #{fonts.$smallLineHeight};
  --mat-sys-label-small-size: #{fonts.$smallFontsize};
  --mat-sys-label-small-tracking: #{fonts.$smallTracking};
  --mat-sys-label-small-weight: #{fonts.$smallWeight};
  --mat-sys-label-small: var(--mat-sys-label-small-weight) var(--mat-sys-label-small-size) /
    var(--mat-sys-label-small-line-height) var(--mat-sys-label-small-font);

  --mat-sys-body-small-font: #{fonts.$font-family};
  --mat-sys-body-small-line-height: #{fonts.$smallLineHeight};
  --mat-sys-body-small-size: #{fonts.$smallFontsize};
  --mat-sys-body-small-tracking: #{fonts.$smallTracking};
  --mat-sys-body-small-weight: #{fonts.$smallWeight};
  --mat-sys-body-small: var(--mat-sys-body-small-weight) var(--mat-sys-body-small-size) /
    var(--mat-sys-body-small-line-height) var(--mat-sys-body-small-font);

  ///////////////////////////////////////////////////////////////
  /// Toolbar
  ///////////////////////////////////////////////////////////////

  --mat-toolbar-title-text-size: 20px;

  ///////////////////////////////////////////////////////////////
  /// Form elements
  ///////////////////////////////////////////////////////////////

  // Form fields
  --mdc-outlined-text-field-focus-outline-width: 1px;
  --mdc-outlined-text-field-caret-color: #{colors.$sq-black};
  --mdc-outlined-text-field-focus-outline-color: #{colors.$sq-black};
  --mdc-outlined-text-field-focus-label-text-color: #{colors.$sq-black};
  --mat-form-field-container-height: 48px;
  --mat-form-field-container-vertical-padding: 14px;
  --mat-form-field-container-text-size: 16px;

  // Selects
  --mat-select-focused-arrow-color: #{colors.$sq-black};
  --mat-option-selected-state-layer-color: #{colors.$sq-orange};

  // Radio buttons
  --mdc-radio-selected-focus-icon-color: #{colors.$sq-black};
  --mdc-radio-selected-hover-icon-color: #{colors.$sq-black};
  --mdc-radio-selected-icon-color: #{colors.$sq-black};
  --mdc-radio-selected-pressed-icon-color: #{colors.$sq-black};
  --mat-radio-checked-ripple-color: none;
  --mat-radio-ripple-color: none;

  // Checkboxes
  --mdc-checkbox-selected-focus-icon-color: #{colors.$sq-black};
  --mdc-checkbox-selected-hover-icon-color: #{colors.$sq-black};
  --mdc-checkbox-selected-icon-color: #{colors.$sq-black};
  --mdc-checkbox-selected-focus-state-layer-color: none;
  --mdc-checkbox-selected-hover-state-layer-color: none;
  --mdc-checkbox-selected-pressed-state-layer-color: none;
  --mdc-checkbox-unselected-focus-state-layer-color: none;
  --mdc-checkbox-unselected-hover-state-layer-color: none;
  --mdc-checkbox-unselected-pressed-state-layer-color: none;

  //////////////////////////////////////////
  /// Buttons
  //////////////////////////////////////////

  --mdc-filled-button-container-shape: #{boxes.$radius-standard};
  --mdc-outlined-button-container-shape: #{boxes.$radius-standard};
  --mdc-protected-button-container-shape: #{boxes.$radius-standard};

  //////////////////////////////////////////
  /// Dialogs
  //////////////////////////////////////////
  --mdc-dialog-container-shape: #{boxes.$radius-standard};
  --mat-dialog-content-padding: #{boxes.$gap};

  //////////////////////////////////////////
  /// Side nav
  //////////////////////////////////////////
  --mat-sidenav-container-shape: 0;

  //////////////////////////////////////////
  /// Accordian
  //////////////////////////////////////////
  --mat-expansion-container-shape: #{boxes.$radius-standard};

  //////////////////////////////////////////
  /// Menu
  ////////////////////////////////////////////
  --mat-menu-item-label-text-color: #{colors.$sq-grey-extra-dark};
  --mat-menu-item-label-text-size: #{fonts.$sub-title-fontsize};
  --mat-menu-item-label-text-weight: #{fonts.$sub-title-weight};
  --mat-menu-item-label-text-line-height: #{fonts.$sub-title-line-height};
  --mat-menu-item-label-text-tracking: #{fonts.$sub-title-tracking};
}

html {
  @include mat.all-component-themes($theme);
  @include mat.typography-hierarchy($theme);
  @include mat.system-level-colors($theme);
  @include mat.system-level-typography($theme);
}

/**
* Specific component overrides, pieces that are not in line with the general theming
*/

/**
* MENU OVERIDES
*/

.mat-mdc-menu-panel {
  border: 1px solid colors.$sq-grey;
  box-shadow: boxes.$box-shadow !important;
  .mat-mdc-menu-content {
    padding: boxes.$gap;
    .mat-mdc-menu-item {
      padding: boxes.$gap;
      border-radius: boxes.$radius-standard;
      .mat-mdc-menu-item-text {
        display: flex;
        align-items: center;
        ng-icon {
          height: boxes.$gap-x2;
          width: boxes.$gap-x2;
          margin-right: boxes.$gap;
        }
      }
      &.active {
        background-color: colors.$sq-orange;
        color: colors.$sq-white;
      }
    }
  }
}

/**
* ICON OVERIDES
*/

.mat-mdc-icon-button {
  .mat-icon {
    font-size: 0.8em !important;
    // fixes bug where bottom of icon is cut off
    padding-top: 1px;
  }
}

.mat-icon {
  // make the font awesome icons slightly smaller to match the sizing of material icons
  font-size: 1.1em !important;
  height: 1.1em !important;
  text-align: center;

  &.green {
    color: colors.$sq-green;
  }
}

i {
  &.fa-light,
  &.fa-solid {
    // Fix icon button alignment
    vertical-align: baseline !important;
  }
}

/**
* RADIO OVERIDES & CHECKBOX OVERIDES
*/

.vertical-group {
  display: flex !important;
  flex-direction: column;
}

/**
* CAPTION OVERIDES
*/

.mat-caption {
  &.bold {
    font-weight: fonts.$font-semibold;
  }
}

// remove auto fill selected
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/**
* FORM FIELD OVERIDES
*/
.mat-mdc-form-field {
  background-color: colors.$sq-white;
}

/**
* MENU OVERIDES
*/

.mat-mdc-menu-item {
  &.warning {
    color: colors.$sq-watermelon;
    font-weight: fonts.$font-semibold;
  }
}

/**
* TAB OVERIDES
*/

// Custom styles for tabs
.mat-mdc-tab-nav-bar {
  margin-bottom: 20px;
  border-bottom: 1px solid colors.$sq-grey;
  .mat-mdc-tab-link {
    font-size: 14px !important;
    font-weight: fonts.$font-semibold !important;
    padding: 0 !important;
    min-width: 0 !important;
    margin-right: 40px;
    height: 28px !important;
    @media screen and (max-width: 600px) {
      margin-right: 15px;
    }
    &:last-child {
      margin-right: 0;
    }
    .mdc-tab__text-label {
      color: colors.$color-font-extra-light !important;
      // opacity: 1 !important;
    }
    &.mdc-tab--active {
      .mdc-tab__text-label {
        color: colors.$sq-black !important;
        // opacity: 1 !important;
      }
      .mdc-tab-indicator__content {
        border-color: colors.$sq-black !important;
      }
    }
    &:hover {
      .mdc-tab__text-label {
        color: colors.$sq-black !important;
      }
    }
    // hide ripple/hover effect
    .mdc-tab__ripple {
      display: none;
    }
  }
  .mat-ink-bar {
    background-color: colors.$sq-black !important;
  }
}
/**
* SNACK BAR OVERIDES
*/
.mat-mdc-snack-bar-handset {
  @media screen and (max-width: 600px) {
    // Extra top padding to stop snackbar falling under safe area and toolbar on phones
    margin-top: 80px !important;
  }
}

/**
* FIXES
*/

// Fix to stop IOS zooming on inputs
@media screen and (max-width: 600px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}

// remove the number stepper and spinner
input[type='number'].remove-number-spinner {
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/**
* CUSTOM DIALOG STYLES
*/
.new-user-overlay {
  width: 600px !important;
  max-width: 100vw !important;
  height: 700px !important;
  max-height: 100vh !important;
  @media screen and (max-width: 600px) {
    width: 100% !important;
    height: 100% !important;
  }
}
.snackbar-wrap-content .message-wrapper {
  white-space: pre-wrap;
  overflow-x: clip;
}

/**
* OLA / DOCUMENT COLLECTION
*/
.ola-radio-group.mat-mdc-radio-group {
  .mat-mdc-radio-button {
    --mdc-radio-selected-icon-color: #{colors.$sq-green};
    --mdc-radio-selected-focus-icon-color: #{colors.$sq-green};
    --mdc-radio-selected-hover-icon-color: #{colors.$sq-green};
    --mdc-radio-selected-pressed-icon-color: #{colors.$sq-green};
    --mat-mdc-radio-checked-ripple-color: #{colors.$sq-green};

    display: flex;
    border-radius: 50px;
    padding: 5px;
    padding-right: 8px;
    padding-left: 16px;
    margin-bottom: 8px;
    // background-color: colors.$sq-grey-body;
    .mdc-form-field--align-end,
    label {
      flex: 1;
    }
  }

  .mat-mdc-radio-checked {
    background-color: colors.$sq-grey-body;
    // background-color: colors.$sq-cyan;
  }
}
