$family: 'Gilroy';

$font-family: $family, 'Century Gothic', Helvetica, Arial, sans-serif;
$font-primary-all: (
  $family 'regular' 400 normal,
  $family 'semibold' 600 normal,
  $family 'bold' 700 normal,
  $family 'black' 800 normal
);
$font-regular: 400;
$font-semibold: 600;
$font-bold: 700;
$font-black: 800;

$brochure-heading-desktop-fontsize: 48px;
$brochure-heading-desktop-line-height: 64px;
$brochure-heading-desktop-tracking: 0;
$brochure-heading-desktop-weight: $font-black;
$brochure-heading-desktop: $brochure-heading-desktop-weight $brochure-heading-desktop-fontsize /
  $brochure-heading-desktop-line-height $font-family;
$brochure-heading-desktop: #{$brochure-heading-desktop-weight} #{$brochure-heading-desktop-fontsize} /
  #{$brochure-heading-desktop-line-height} #{$font-family};

$brochure-heading-mobile-fontsize: 32px;
$brochure-heading-mobile-line-height: 40px;
$brochure-heading-mobile-tracking: 0;
$brochure-heading-mobile-weight: $font-black;
$brochure-heading-mobile: $brochure-heading-mobile-weight $brochure-heading-mobile-fontsize /
  $brochure-heading-mobile-line-height $font-family;
$brochure-heading-mobile: #{$brochure-heading-mobile-weight} #{$brochure-heading-mobile-fontsize} /
  #{$brochure-heading-mobile-line-height} #{$font-family};

$page-title-fontsize: 32px;
$page-title-line-height: 48px;
$page-title-tracking: 0;
$page-title-weight: $font-semibold;
$page-title: $page-title-weight $page-title-fontsize / $page-title-line-height $font-family;
$page-title: #{$page-title-weight} #{$page-title-fontsize} / #{$page-title-line-height} #{$font-family};

$section-title-fontsize: 24px;
$section-title-line-height: 32px;
$section-title-tracking: 0;
$section-title-weight: $font-semibold;
$section-title: #{$section-title-weight} #{$section-title-fontsize} / #{$section-title-line-height} #{$font-family};

$sub-title-fontsize: 16px;
$sub-title-line-height: 24px;
$sub-title-tracking: 0;
$sub-title-weight: $font-semibold;
$sub-title: #{$sub-title-weight} #{$sub-title-fontsize} / #{$sub-title-line-height} #{$font-family};

$copy-fontsize: 14px;
$copy-line-height: 20px;
$copy-tracking: 0;
$copy-weight: $font-regular;
$copy: #{$copy-weight} #{$copy-fontsize} / #{$copy-line-height} #{$font-family};

$smallFontsize: 12px;
$smallLineHeight: 20px;
$smallTracking: 0;
$smallWeight: $font-regular;
$small: #{$smallWeight} #{$smallFontsize} / #{$smallLineHeight} #{$font-family};

////////////////////////
// Mixins
////////////////////////

@mixin brochureHeading() {
  font: $brochure-heading-desktop;
  line-height: $brochure-heading-desktop-line-height;
  @media screen and (max-width: 900px) {
    font: $brochure-heading-mobile;
    line-height: $brochure-heading-mobile-line-height;
  }
}

@mixin pageTitle() {
  font: $page-title;
  line-height: $page-title-line-height;
}

@mixin sectionTitle() {
  font: $section-title;
  line-height: $section-title-line-height;
}

@mixin subTitle() {
  font: $sub-title;
  line-height: $sub-title-line-height;
}

@mixin copy() {
  font: $copy;
  line-height: $copy-line-height;
}

@mixin small() {
  font: $small;
  line-height: $smallLineHeight;
}
