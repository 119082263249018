@use '/src/app/core/styles/colors';
@use '/src/app/core/styles/fonts';
@use '/src/app/core/styles/reboot';
@use '/src/app/core/styles/materialtheme';
// Because the buttons use a directive, we need to import the styles globally
@use '/src/app/components/button/sq-button.directive.scss';

////////////////////////
/// Import fonts
////////////////////////

@each $family, $file, $weight, $style in fonts.$font-primary-all {
  @font-face {
    font-family: fonts.$family;
    src: url('/assets/fonts/gilroy/gilroy-#{$file}.woff2') format('woff2');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

body {
  margin: 0;
  color: colors.$sq-black;
}
html {
  height: 100%;
  /*
  * this is to stop the page from jumping
  * horizontally when  the scroll bar appears
  */
  overflow-y: scroll;
}

@media print {
  /*
  * Fix to make tables render correctly when printed
  */
  @page {
    size: A4;
    margin: 10mm 0;
  }
  html,
  body {
    margin: 0;
    padding: 0;
    color: colors.$sq-black;
    background: colors.$sq-white;
    // 900 is to emulate an A4 page in portrait
    min-width: 900px;
    table {
      page-break-inside: auto;
      tr {
        page-break-inside: avoid;
        page-break-after: auto;
      }
      thead {
        display: table-header-group;
      }
      tfoot {
        display: table-footer-group;
      }
    }
    .mat-drawer-content {
      height: auto;
    }
  }
}

* {
  // disable touch actions on the native apps to stop weird side effects e.g. zooming in on pinpad after double taps
  touch-action: manipulation;
}

b,
strong {
  font-weight: fonts.$font-semibold;
}

// Mimic the generic :focus state color for text selection too.
::selection {
  background: colors.$sq-cyan;
  text-shadow: none;
}

/*
* CUSTOM SCROLL BAR STYLES
*/

// /* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: colors.$sq-grey-dark;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: colors.$sq-grey-extra-dark;
}

/*
* Remove blue highlight on mobile tap
*/

* {
  -webkit-tap-highlight-color: transparent;
}
